import React, { useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";

import { Stack, Typography, Rating, Pagination } from "@mui/material";
import { SearchOutlined, AddLocationAlt } from "@mui/icons-material";

import { useApi } from "../../hooks/useApi";
import { API_URL } from "../../config/apiUrl";

import { Container, Row, Col, Button, Avatar, InputMask } from "..";
import { ModalPintor } from "./components/ModalPintor";

const Visitante = () => {
  const [cep, setCep] = useState("");
  const [open, setOpen] = useState(false);
  const [pintor, setPintor] = useState(false);
  const [pagination, setPagination] = useState(1);

  const [{ data, loading }, fetch] = useApi(
    {
      ...API_URL.PINTORES_CEP,
      params: { cep, take: 50, skip: (pagination - 1) * 50 },
    },
    { manual: true }
  );

  const ucWords = (str) => {
    return (str + "")
      .toLowerCase()
      .replace(/^([a-z])|\s+([a-z])/g, function ($1) {
        return $1.toUpperCase();
      });
  };

  const onCep = (e) => setCep(e.target.value);

  const onSubmit = () => fetch();

  const onPintor = (item) => {
    setOpen(true);
    setPintor(item);
  };

  const onClosePintor = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (cep) {
      onSubmit();
    }
  }, [pagination]);
  console.log(pagination);

  return (
    <Container loading={loading} id="cep">
      <Row
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={styles.boxCep}
        mt={2}
        mb={4}
      >
        <Col xs={12}>
          <Typography
            color="secondary"
            variant="h3"
            fontSize={28}
            fontStyle="italic"
            fontWeight={700}
          >
            Encontre pintores certificados pela Sherwin-Williams, próximos a
            você!
          </Typography>{" "}
        </Col>
  
        <Col xs={12}>
          <Stack
            spacing={2}
            width="100%"
            direction={"row"}
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              color="secondary"
              fontWeight={700}
              fontStyle={"italic"}
              mt={"0.5rem"}
            >
              Receba orçamentos: Digite seu CEP e confira todos os pintores
              profissionais certificados na sua região!
            </Typography>{" "}
          </Stack>
        </Col>
  
        <Col xs={12} md={12}>
          <Formik
            initialValues={{}}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            {() => (
              <Form>
                <Stack
                  spacing={2}
                  width="100%"
                  direction={{ md: "row", xs: "column" }}
                  alignItems={"center"}
                >
                  <Field
                    as={InputMask}
                    mask="99999-999"
                    name="cep"
                    placeholder="CEP"
                    fullWidth
                    onChange={onCep}
                  />
  
                  <Button color="primary" sx={{ width: "200px" }} type="submit">
                    <SearchOutlined />
                    BUSCAR
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </Col>
  
        {data?.endereco && (
          <Col xs={12} justifyContent="center" alignItems="center" container>
            <Stack sx={styles.box}>
              <Typography
                variant="h5"
                fontWeight={600}
                textAlign="center"
                color="gray.main"
              >
                {data?.endereco.endereco} - {data?.endereco.cidade} -{" "}
                {data?.endereco.uf}
              </Typography>
            </Stack>
          </Col>
        )}
      </Row>
  
      {data?.pintores && (
        <Row spacing={2} justifyContent="center" alignItems="center" mb={4}>
          <Col xs={12}>
            <Stack
              spacing={2}
              width="100%"
              direction={"row"}
              justifyContent="center"
              alignItems="center"
            >
              <AddLocationAlt sx={{ fontSize: 100 }} color="secondary" />
              <Typography color="secondary" fontWeight={700} variant="h4">
                {data.pintores.length > 0 
                  ? "Encontramos estes \n profissionais na sua região:"
                  : "Não encontramos pintores \n nessa região"}
              </Typography>
            </Stack>
          </Col>
  
          {data.pintores.length > 0 && (
            <>
              <Col xs={12} sx={styles.boxPintores} mt={2}>
                <Row
                  direction={"row"}
                  justifyContent="center"
                  alignItems="center"
                  gap="1rem"
                >
                  {data?.pintores.map((pintor) => (
                    <Col xs={8} sm={4} md={2} key={pintor.id}>
                      <Stack
                        spacing={1.4}
                        alignItems="center"
                        sx={styles.boxPintor}
                      >
                        <Avatar
                          alt={pintor.nome}
                          src={pintor.avatar}
                          sx={{ width: 90, height: 90 }}
                        />
  
                        <Rating
                          name="read-only"
                          value={pintor.notaMedia}
                          readOnly
                        />
                        <div style={{ textAlign: "center" }}>
                          <Typography
                            fontWeight={600}
                            variant="body2"
                            fontSize={14}
                          >
                            {ucWords(
                              pintor.nome.length > 15
                                ? pintor.nome.substr(0, 15) + "..."
                                : pintor.nome
                            )}
                          </Typography>
                        </div>
  
                        <Button
                          size="small"
                          color="primary"
                          fullWidth
                          onClick={onPintor.bind(this, pintor)}
                        >
                          Saiba mais
                        </Button>
                      </Stack>
                    </Col>
                  ))}
                </Row>
              </Col>
  
              <Col
                xs={12}
                justifyContent="center"
                alignItems="center"
                container
                mt={2}
              >
                <Pagination
                  count={Math.ceil((data?.total || 1) / 50)}
                  onChange={(e, value) => {
                    setPagination(Number(value));
                  }}
                  color="secondary"
                  variant="outlined"
                  shape="rounded"
                />
              </Col>
            </>
          )}
        </Row>
      )}
  
      {open && (
        <ModalPintor open={open} onClose={onClosePintor} pintor={pintor} />
      )}
    </Container>
  );
  
};

const styles = {
  boxCep: {
    background: "#f2f2f2",
    borderRadius: "22px",
    padding: "1rem",
  },
  box: {
    background: "#fff",
    borderRadius: "22px",
    padding: "1rem",
    minHeight: "100%",
  },
  boxPintores: {
    background: "#f2f2f2",
    borderRadius: "22px",
    padding: "1rem",
  },
  boxPintor: {
    background: "#fff",
    height: "215px",
    justifyContent: "center",
    padding: "0.6rem",
    borderRadius: "1rem",
    width: "100%",
  },
};

export default Visitante;

